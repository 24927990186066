import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import DefaultContent from "../components/Global/DefaultContent/defaultContent"

const PrivacyPolicyPage = () => (
  <Layout>
    <SEO
      title="Madfish | Software development company experienced in Blockchain and JS web"
      description="We offer full-cycle development (discovery phase and business analysis, software architecture and development, project management, QA, support and launching)"
    />
    <DefaultContent>
      <h1>Terms of use</h1>
      <p>Effective date: 06/22/2020</p>
      <h2>1. Introduction</h2>
      <p>
        Welcome to <strong>MadFish.Solutions</strong> (
        <strong>“Company”</strong>, <strong>“we”</strong>,{" "}
        <strong>“our”</strong>, <strong>“us”)</strong>! As you have just clicked
        our Terms of Service, please pause, grab a cup of coffee and carefully
        read the following pages. It will take you approximately 20 minutes.
      </p>
      <p>
        These Terms of Service (<strong>“Terms”</strong>,{" "}
        <strong>“Terms of Service”</strong>) govern your use of our web pages
        located at <Link to="/">https://www.madfish.solutions</Link> operated by
        MadFish.Solutions.
      </p>
      <p>
        Our Privacy Policy also governs your use of our Service and explains how
        we collect, safeguard and disclose information that results from your
        use of our web pages. Please read it here{" "}
        <Link to="/privacy-policy">
          https://www.madfish.solutions/privacy-policy
        </Link>
        .
      </p>
      <p>
        Your agreement with us includes these Terms and our Privacy Policy (
        <strong>“Agreements”</strong>). You acknowledge that you have read and
        understood Agreements, and agree to be bound of them.
      </p>
      <p>
        If you do not agree with (or cannot comply with) Agreements, then you
        may not use the Service, but please let us know by emailing at{" "}
        <a href="info@madfish.solutions">info@madfish.solutions</a> so we can
        try to find a solution. These Terms apply to all visitors, users and
        others who wish to access or use Service.
      </p>
      <p>Thank you for being responsible.</p>

      <h2>2. Communications</h2>
      <p>
        By using our Service, you agree to subscribe to newsletters, marketing
        or promotional materials and other information we may send. However, you
        may opt out of receiving any, or all, of these communications from us by
        following the unsubscribe link or by emailing at.
      </p>

      <h2>3. Contests, Sweepstakes and Promotions</h2>
      <p>
        Any contests, sweepstakes or other promotions (collectively,
        <strong>“Promotions”</strong>) made available through Service may be
        governed by rules that are separate from these Terms of Service. If you
        participate in any Promotions, please review the applicable rules as
        well as our Privacy Policy. If the rules for a Promotion conflict with
        these Terms of Service, Promotion rules will apply.
      </p>

      <h2>4. Fee Changes</h2>
      <p>
        MadFish.Solutions, in its sole discretion and at any time, may modify
        Subscription fees for the Subscriptions. Any Subscription fee change
        will become effective at the end of the then-current Billing Cycle.
      </p>
      <p>
        MadFish.Solutions will provide you with a reasonable prior notice of any
        change in Subscription fees to give you an opportunity to terminate your
        Subscription before such change becomes effective.
      </p>
      <p>
        Your continued use of Service after Subscription fee change comes into
        effect constitutes your agreement to pay the modified Subscription fee
        amount.
      </p>

      <h2>5. Refunds</h2>
      <p>
        Except when required by law, paid Subscription fees are non-refundable.
      </p>

      <h2>6. Analytics</h2>
      <p>
        We may use third-party Service Providers to monitor and analyze the use
        of our Service.
      </p>
      <h3>Google Analytics</h3>
      <p>
        Google Analytics is a web analytics service offered by Google that
        tracks and reports website traffic. Google uses the data collected to
        track and monitor the use of our Service. This data is shared with other
        Google services. Google may use the collected data to contextualise and
        personalise the ads of its own advertising network.
      </p>
      <p>
        For more information on the privacy practices of Google, please visit
        the Google Privacy Terms web page:{" "}
        <a
          href="https://policies.google.com/privacy?hl=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://policies.google.com/privacy?hl=en
        </a>
        .
      </p>
      <p>
        We also encourage you to review the Google's policy for safeguarding
        your data:{" "}
        <a
          href="https://support.google.com/analytics/answer/6004245"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://support.google.com/analytics/answer/6004245
        </a>
        .
      </p>
      <h3>Firebase</h3>
      <p>Firebase is analytics service provided by Google Inc.</p>
      <p>
        You may opt-out of certain Firebase features through your mobile device
        settings, such as your device advertising settings or by following the
        instructions provided by Google in their Privacy Policy:{" "}
        <a
          href="https://policies.google.com/privacy?hl=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://policies.google.com/privacy?hl=en
        </a>
        .
      </p>
      <p>
        For more information on what type of information Firebase collects,
        please visit the Google Privacy Terms web page:{" "}
        <a
          href="https://policies.google.com/privacy?hl=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://policies.google.com/privacy?hl=en
        </a>
        .
      </p>

      <h2>7. No Use By Minors</h2>
      <p>
        Service is intended only for access and use by individuals at least
        eighteen (18) years old. By accessing or using any of Company, you
        warrant and represent that you are at least eighteen (18) years of age
        and with the full authority, right, and capacity to enter into this
        agreement and abide by all of the terms and conditions of Terms. If you
        are not at least eighteen (18) years old, you are prohibited from both
        the access and usage of Service.
      </p>

      <h2>8. Accounts</h2>
      <p>
        When you create an account with us, you guarantee that you are above the
        age of 18, and that the information you provide us is accurate,
        complete, and current at all times. Inaccurate, incomplete, or obsolete
        information may result in the immediate termination of your account on
        Service.
      </p>
      <p>
        You are responsible for maintaining the confidentiality of your account
        and password, including but not limited to the restriction of access to
        your computer and/or account. You agree to accept responsibility for any
        and all activities or actions that occur under your account and/or
        password, whether your password is with our Service or a third-party
        service. You must notify us immediately upon becoming aware of any
        breach of security or unauthorized use of your account.
      </p>
      <p>
        You may not use as a username the name of another person or entity or
        that is not lawfully available for use, a name or trademark that is
        subject to any rights of another person or entity other than you,
        without appropriate authorization. You may not use as a username any
        name that is offensive, vulgar or obscene.
      </p>
      <p>
        We reserve the right to refuse service, terminate accounts, remove or
        edit content, or cancel orders in our sole discretion.
      </p>

      <h2>9. Intellectual Property</h2>
      <p>
        Service and its original content (excluding Content provided by users),
        features and functionality are and will remain the exclusive property of
        MadFish.Solutions and its licensors. Service is protected by copyright,
        trademark, and other laws of the United States and foreign countries.
        Our trademarks and trade dress may not be used in connection with any
        product or service without the prior written consent of
        MadFish.Solutions.
      </p>

      <h2>10. Error Reporting and Feedback</h2>
      <p>
        You may provide us directly at{" "}
        <a href="info@madfish.solutions">info@madfish.solutions</a> with
        information and feedback concerning errors, suggestions for
        improvements, ideas, problems, complaints, and other matters related to
        our Service (<strong>“Feedback”</strong>). You acknowledge and agree
        that:
      </p>
      <ol>
        <li>
          you shall not retain, acquire or assert any intellectual property
          right or other right, title or interest in or to the Feedback;
        </li>
        <li> Company may have development ideas similar to the Feedback;</li>
        <li>
          Feedback does not contain confidential information or proprietary
          information from you or any third party;
        </li>
        <li>
          Company is not under any obligation of confidentiality with respect to
          the Feedback.
        </li>
      </ol>
      <p>
        In the event the transfer of the ownership to the Feedback is not
        possible due to applicable mandatory laws, you grant Company and its
        affiliates an exclusive, transferable, irrevocable, free-of-charge,
        sub-licensable, unlimited and perpetual right to use (including copy,
        modify, create derivative works, publish, distribute and commercialize)
        Feedback in any manner and for any purpose.
      </p>

      <h2>11. Links To Other Web Sites</h2>
      <p>
        Our Service may contain links to third party web sites or services that
        are not owned or controlled by MadFish.Solutions.
      </p>
      <p>
        MadFish.Solutions has no control over, and assumes no responsibility for
        the content, privacy policies, or practices of any third party web sites
        or services. We do not warrant the offerings of any of these
        entities/individuals or their websites.
      </p>
      <p>
        YOU ACKNOWLEDGE AND AGREE THAT MADFISH.SOLUTIONS SHALL NOT BE
        RESPONSIBLE OR LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY DAMAGE OR LOSS
        CAUSED OR ALLEGED TO BE CAUSED BY OR IN CONNECTION WITH USE OF OR
        RELIANCE ON ANY SUCH CONTENT, GOODS OR SERVICES AVAILABLE ON OR THROUGH
        ANY SUCH THIRD PARTY WEB SITES OR SERVICES.
      </p>
      <p>
        WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE AND PRIVACY POLICIES
        OF ANY THIRD PARTY WEB SITES OR SERVICES THAT YOU VISIT.
      </p>

      <h2>12. Disclaimer Of Warranty </h2>
      <p>
        THESE SERVICES ARE PROVIDED BY COMPANY ON AN “AS IS” AND “AS AVAILABLE”
        BASIS. COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND,
        EXPRESS OR IMPLIED, AS TO THE OPERATION OF THEIR SERVICES, OR THE
        INFORMATION, CONTENT OR MATERIALS INCLUDED THEREIN. YOU EXPRESSLY AGREE
        THAT YOUR USE OF THESE SERVICES, THEIR CONTENT, AND ANY SERVICES OR
        ITEMS OBTAINED FROM US IS AT YOUR SOLE RISK.
      </p>
      <p>
        NEITHER COMPANY NOR ANY PERSON ASSOCIATED WITH COMPANY MAKES ANY
        WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY,
        RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE SERVICES. WITHOUT
        LIMITING THE FOREGOING, NEITHER COMPANY NOR ANYONE ASSOCIATED WITH
        COMPANY REPRESENTS OR WARRANTS THAT THE SERVICES, THEIR CONTENT, OR ANY
        SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL BE ACCURATE,
        RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED,
        THAT THE SERVICES OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF
        VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE SERVICES OR ANY SERVICES
        OR ITEMS OBTAINED THROUGH THE SERVICES WILL OTHERWISE MEET YOUR NEEDS OR
        EXPECTATIONS.
      </p>
      <p>
        COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
        IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY
        WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR
        PARTICULAR PURPOSE.
      </p>
      <p>
        THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR
        LIMITED UNDER APPLICABLE LAW.
      </p>

      <h2>13. Limitation Of Liability</h2>
      <p>
        EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR OFFICERS,
        DIRECTORS, EMPLOYEES, AND AGENTS HARMLESS FOR ANY INDIRECT, PUNITIVE,
        SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGE, HOWEVER IT ARISES
        (INCLUDING ATTORNEYS' FEES AND ALL RELATED COSTS AND EXPENSES OF
        LITIGATION AND ARBITRATION, OR AT TRIAL OR ON APPEAL, IF ANY, WHETHER OR
        NOT LITIGATION OR ARBITRATION IS INSTITUTED), WHETHER IN AN ACTION OF
        CONTRACT, NEGLIGENCE, OR OTHER TORTIOUS ACTION, OR ARISING OUT OF OR IN
        CONNECTION WITH THIS AGREEMENT, INCLUDING WITHOUT LIMITATION ANY CLAIM
        FOR PERSONAL INJURY OR PROPERTY DAMAGE, ARISING FROM THIS AGREEMENT AND
        ANY VIOLATION BY YOU OF ANY FEDERAL, STATE, OR LOCAL LAWS, STATUTES,
        RULES, OR REGULATIONS, EVEN IF COMPANY HAS BEEN PREVIOUSLY ADVISED OF
        THE POSSIBILITY OF SUCH DAMAGE. EXCEPT AS PROHIBITED BY LAW, IF THERE IS
        LIABILITY FOUND ON THE PART OF COMPANY, IT WILL BE LIMITED TO THE AMOUNT
        PAID FOR THE PRODUCTS AND/OR SERVICES, AND UNDER NO CIRCUMSTANCES WILL
        THERE BE CONSEQUENTIAL OR PUNITIVE DAMAGES. SOME STATES DO NOT ALLOW THE
        EXCLUSION OR LIMITATION OF PUNITIVE, INCIDENTAL OR CONSEQUENTIAL
        DAMAGES, SO THE PRIOR LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.
      </p>

      <h2>14. Governing Law</h2>
      <p>
        These Terms shall be governed and construed in accordance with the laws
        of Ukraine without regard to its conflict of law provisions.
      </p>
      <p>
        Our failure to enforce any right or provision of these Terms will not be
        considered a waiver of those rights. If any provision of these Terms is
        held to be invalid or unenforceable by a court, the remaining provisions
        of these Terms will remain in effect. These Terms constitute the entire
        agreement between us regarding our Service and supersede and replace any
        prior agreements we might have had between us regarding Service.
      </p>

      <h2>15. Changes To Service</h2>
      <p>
        We reserve the right to withdraw or amend our Service, and any service
        or material we provide via Service, in our sole discretion without
        notice. We will not be liable if for any reason all or any part of
        Service is unavailable at any time or for any period. From time to time,
        we may restrict access to some parts of Service, or the entire Service,
        to users, including registered users.
      </p>

      <h2>16. Amendments To Terms</h2>
      <p>
        We may amend Terms at any time by posting the amended terms on this
        site. It is your responsibility to review these Terms periodically.
      </p>
      <p>
        Your continued use of the Platform following the posting of revised
        Terms means that you accept and agree to the changes. You are expected
        to check this page frequently so you are aware of any changes, as they
        are binding on you.
      </p>
      <p>
        By continuing to access or use our Service after any revisions become
        effective, you agree to be bound by the revised terms. If you do not
        agree to the new terms, you are no longer authorized to use Service.
      </p>

      <h2>17. Waiver And Severability</h2>
      <p>
        No waiver by Company of any term or condition set forth in Terms shall
        be deemed a further or continuing waiver of such term or condition or a
        waiver of any other term or condition, and any failure of Company to
        assert a right or provision under Terms shall not constitute a waiver of
        such right or provision.
      </p>
      <p>
        If any provision of Terms is held by a court or other tribunal of
        competent jurisdiction to be invalid, illegal or unenforceable for any
        reason, such provision shall be eliminated or limited to the minimum
        extent such that the remaining provisions of Terms will continue in full
        force and effect.
      </p>

      <h2>18. Acknowledgement</h2>
      <p>
        BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU ACKNOWLEDGE THAT
        YOU HAVE READ THESE TERMS OF SERVICE AND AGREE TO BE BOUND BY THEM.
      </p>

      <h2>19. Contact Us</h2>
      <p>
        Please send your feedback, comments, requests for technical support:
      </p>
      <p>
        By email: <a href="info@madfish.solutions">info@madfish.solutions</a>.
      </p>
    </DefaultContent>
  </Layout>
)

export default PrivacyPolicyPage
